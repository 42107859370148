<!--
 * @Description: ==================  =================
 * @Date: 2022-05-12 11:37:41
 * @Author: Zero
 * @FilePath: \beian-web\src\components\Section\S7.vue
 * @LastEditors: Zero
 * @LastEditTime: 2023-06-25 15:35:34
-->
<template>
  <section id="pricing-plan4" class="pricing-plan4">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-md-offset-2 text-center">
          <h1 class="section-title">创量盈兆</h1>
          <p class="section-para">一家致力于精细化服务的全球发行商。年轻的团队成员拥有着成功的发行经验、丰富的服务经验，在线产品超百款，经典IP、世界IP、动漫IP数十款，服务玩家数超过一亿人。</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ul class="plans list-inline text-center">
            <li class="plan-item">
              <ul class="single-plan text-center">
                <li class="plan-icon"><i class="fa fa-home"></i></li>
                <li class="plan-title">Startup</li>
                <li class="plan-tag">Small business solution</li>
                <li class="plan-price">
                  <span class="price-lay">$29<sub>Per Month</sub></span>
                  <span class="price-overlay">$29<sub>Per Month</sub></span>
                </li>
                <li>50GB Storage</li>
                <li>50 Emails</li>
                <li>50 Domains</li>
                <li>5GB Bandwidth</li>
                <li><a href="/#" class="btn btn-info button">Sign Up</a></li>
              </ul>
            </li>
            <li class="plan-item plan-center">
              <ul class="single-plan text-center">
                <li class="plan-icon"><i class="fa fa-rocket"></i></li>
                <li class="plan-title">Standard</li>
                <li class="plan-tag">Medium business solution</li>
                <li class="plan-price">
                  <span class="price-lay">$39<sub>Per Month</sub></span>
                  <span class="price-overlay">$39<sub>Per Month</sub></span>
                </li>
                <li>50GB Storage</li>
                <li>50 Emails</li>
                <li>50 Domains</li>
                <li>5GB Bandwidth</li>
                <li><a href="/#" class="btn btn-info button">Sign Up</a></li>
              </ul>
            </li>
            <li class="plan-item">
              <ul class="single-plan text-center">
                <li class="plan-icon"><i class="fa fa-diamond"></i></li>
                <li class="plan-title">Premium</li>
                <li class="plan-tag">Gigantic business solution</li>
                <li class="plan-price">
                  <span class="price-lay">$49<sub>Per Month</sub></span>
                  <span class="price-overlay">$49<sub>Per Month</sub></span>
                </li>
                <li>50GB Storage</li>
                <li>50 Emails</li>
                <li>50 Domains</li>
                <li>5GB Bandwidth</li>
                <li><a href="/#" class="btn btn-info button">Sign Up</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
