<!--
 * @Description: ================== app =================
 * @Date: 2022-05-11 17:21:00
 * @Author: Zero
 * @FilePath: \beian-web\src\App.vue
 * @LastEditors: Zero
 * @LastEditTime: 2022-05-12 11:45:34
-->
<template>
  <div id="app">
    <HeaderComponent />
    <PageComponent />
    <FooterComponent />
  </div>
</template>

<script>
import PageComponent from './components/Page.vue'
import HeaderComponent from './components/Header.vue'
import FooterComponent from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    PageComponent,
    FooterComponent
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>
